import { SliderDataProps } from '../components/slider/slider';
import { Donation } from '../app-state/app-state';

// The reverse process from 'create-sliders'. Here we take the data given back from the combination
// of donation data and slider info, and extract the donation data.
export const getDonationsDataFromSlidersData = (
  sliders: SliderDataProps[]
): Donation[] => {
  if (!sliders.length) {
    return [];
  }

  return sliders.map(({ percentage, charity }) => ({
    percentage,
    charity,
  }));
};
