import { NavLink } from 'react-router-dom';
import { useFruitcakeStore } from '../../app-state/app-state';
import {
  LoggedInRoutesConfig,
  LoggedOutRoutesConfig,
} from '../../routes/routes-config';

export const FooterNavItems = () => {
  const { appState } = useFruitcakeStore();
  const isLoggedIn = !!appState.email;

  const routes = isLoggedIn ? LoggedInRoutesConfig : LoggedOutRoutesConfig;

  return (
    <ul
      data-testid="footer-nav-items"
      className="flex flex-col gap-y-4 gap-x-0 sm:flex-row sm:items-center sm:justify-end text-right sm:gap-y-0 sm:gap-x-7 sm:pl-7"
    >
      {routes.reduce((acc, route) => {
        if (route.includeInFooter) {
          acc.push(
            <li key={route.path} className="link">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'font-medium text-blue-600 sm:py-6 dark:text-blue-500'
                    : 'font-medium text-gray-500 hover:text-gray-400 sm:py-6 dark:text-gray-400 dark:hover:text-gray-500'
                }
                to={route.path}
              >
                {route.name}
              </NavLink>
            </li>
          );
        }
        return acc;
      }, [] as JSX.Element[])}
    </ul>
  );
};
