import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Charity } from '../../app-state/app-state';
import { ApiRoutes, getApiUrl } from '../../utils/get-api-url';
import { constructGetRequestOptions } from '../../utils/construct-get-request-options';
import { useFruitcakeStore } from '../../app-state/app-state';
import { CharityAdd } from '../../components/charity-add/charity-add';
import { Card } from '../../components/card/card';

interface CharityDataProps {
  charity: Charity | null;
  charityFetchInProgress: boolean;
  charityFetchError: Error | null;
}

export const CharityDetails = () => {
  const actions = useFruitcakeStore((state) => state.actions);
  const { charityId } = useParams<{ charityId: string }>();
  const [charityData, setCharityData] = useState<CharityDataProps>({
    charity: null,
    charityFetchInProgress: false,
    charityFetchError: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (charityId) {
      try {
        const requestOptions = constructGetRequestOptions();

        const handleSuccess = (data: any) => {
          const foundCharity = data.results.find(
            (c: Charity) => parseInt(c.id, 10) === parseInt(charityId, 10),
          );
          if (foundCharity) {
            setCharityData({
              charity: foundCharity,
              charityFetchInProgress: false,
              charityFetchError: null,
            });
          }
        };

        actions.addToFetchQueue(
          `${getApiUrl()}${ApiRoutes.CHARITY_LIST}`,
          requestOptions,
          handleSuccess,
        );
      } catch (error) {
        setCharityData({
          charity: null,
          charityFetchInProgress: false,
          charityFetchError: error as Error,
        });
      }
    } else {
      setCharityData({
        charity: null,
        charityFetchInProgress: false,
        charityFetchError: new Error('No charity ID provided'),
      });
    }
  }, [charityId, actions]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="mb-2">
        <button
          onClick={handleBackClick}
          className="link flex items-center gap-1"
        >
          &#8592; Back
        </button>
      </div>

      {charityData.charity ? (
        <>
          <div className="w-full h-32 md:h-52 lg:h-96 max-h-96 rounded-xl overflow-hidden border border-gray-600 my-10">
            <img
              src={charityData.charity.banner_url}
              className="object-cover object-center w-full h-full"
              alt={`${charityData.charity.name} banner`}
            />
          </div>

          <div className="flex justify-between flex-wrap items-center gap-4 mb-6">
            <div className="flex items-center gap-4">
              <div className="rounded-md p-1 bg-white h-16 w-16">
                <img
                  src={charityData.charity.logo_url}
                  alt={`${charityData.charity.name} logo`}
                />
              </div>

              <h1 className="text-2xl font-semibold m-0">
                {charityData.charity.name}
              </h1>
            </div>

            <div className="w-[300px] rounded overflow-hidden">
              <CharityAdd charity={charityData.charity} textFlexAlign="row" />
            </div>
          </div>

          {charityData.charity.categories.length > 0 && (
            <div className="border-t border-gray-300 dark:border-gray-500 py-6">
              <ul className="flex flex-wrap gap-4">
                {charityData.charity.categories.map((category) => (
                  <li key={category}>
                    <Card defaultPadding={false} shadow={false}>
                      <div className="text-sm p-2 pointer-events-none">
                        {category}
                      </div>
                    </Card>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="border-t border-gray-300 dark:border-gray-500 pt-6">
            <h2 className="lowercase italic">In their own words</h2>
            <p className="mt-2">{charityData.charity.strapline}</p>
            <p className="mt-2">{charityData.charity.description}</p>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};
