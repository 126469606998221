import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFruitcakeStore } from '../../app-state/app-state';
import { ApiRoutes, getApiUrl } from '../../utils/get-api-url';
import { routes } from '../../routes/routes';
import { Button } from '../button/button';
import { DataLoader } from '../data-loader/data-loader';
import { usePostRequest } from '../../utils/use-post-request';

interface LogoutState {
  fetching: boolean;
  error: Error | null;
  showStatus: boolean;
}

export const Logout = () => {
  const actions = useFruitcakeStore((state) => state.actions);
  const { post, csrfTokenLoading } = usePostRequest();
  const navigate = useNavigate();

  const [logoutState, setLogoutState] = useState<LogoutState>({
    fetching: false,
    error: null,
    showStatus: false,
  });

  const logout = async () => {
    setLogoutState((prevState) => ({
      ...prevState,
      fetching: true,
      showStatus: true,
    }));

    if (!csrfTokenLoading) {
      const url = `${getApiUrl()}${ApiRoutes.AUTH_LOGOUT}`;
      const body = {};
      const handleSuccess = () => {
        setLogoutState((prevState) => ({
          ...prevState,
          fetching: false,
        }));
        actions.clearUserData();
        navigate(routes.HOME);
      };
      const handleError = (error: Error) => {
        setLogoutState((prevState) => ({
          ...prevState,
          fetching: false,
          error: error,
        }));
      };

      await post(url, body, handleSuccess, handleError);
    }
  };

  return (
    <>
      <Button dataTestId="logout-btn" variant="CTA" onClick={() => logout()}>
        logout
      </Button>

      {logoutState.showStatus && (
        <DataLoader
          data={true}
          loading={logoutState.fetching}
          error={logoutState.error}
          render={() => <p>You have been logged out</p>}
        />
      )}
    </>
  );
};
