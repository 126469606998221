import { useState, useEffect, useRef } from 'react';
import { LockOpenIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { usePostRequest } from '../../utils/use-post-request';
import { getApiUrl, ApiRoutes } from '../../utils/get-api-url';
import { Button } from '../button/button';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '../dialog/dialog';
import { useFruitcakeStore } from '../../app-state/app-state';
import { routes } from '../../routes/routes';

interface AuthState {
  fetching: boolean;
  error: Error | null;
  link: string;
}

export const LoginDialog = () => {
  const { post, csrfTokenLoading } = usePostRequest();
  const [formEmail, setFormEmail] = useState('');
  const [authState, setAuthState] = useState<AuthState>({
    fetching: false,
    error: null,
    link: '',
  });
  const emailInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const {
    actions: { closeLoginDialog, setEmailLinkSent },
    appState: { isLoginDialogOpen, email },
  } = useFruitcakeStore((state) => state);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  // If the user is logged in, we don't need to show the login dialog
  if (email) return null;

  const getAuthLink = async (email: string) => {
    setAuthState({
      error: null,
      link: '',
      fetching: true,
    });

    if (!csrfTokenLoading) {
      const url = `${getApiUrl()}${ApiRoutes.AUTH_LOGIN_REGISTER}`;
      const body = { email };

      const handleSuccess = (data: any) => {
        setAuthState({
          fetching: false,
          error: null,
          link: data.message,
        });
        closeLoginDialog();
        setEmailLinkSent(true);
        navigate(routes.CHECK_EMAIL);
      };

      const handleError = (error: Error) => {
        setAuthState({
          fetching: false,
          error,
          link: '',
        });
      };

      await post(url, body, handleSuccess, handleError);
    }
  };

  return (
    <Dialog isOpen={isLoginDialogOpen} onClose={closeLoginDialog}>
      <div className="w-[400px]">
        {csrfTokenLoading ? (
          <div className="flex items-center gap-2 text-sm font-semibold mb-6">
            <LockOpenIcon className="h-5 w-5" />
            <span>Attempting secure connection with the server...</span>
          </div>
        ) : (
          <div className="flex items-center gap-2 text-sm font-semibold mb-6">
            <LockClosedIcon className="h-5 w-5" />
            <span>Connection secured</span>
          </div>
        )}
        <h2 data-testid="signup">Signup</h2>
        <Button variant="CTA">
          <a
            target="_blank"
            href="https://9afe9117.sibforms.com/serve/MUIFAKY8flGCkbCt4gNkGTSir5UVLsM87kHz4W5u8JGfF6vU_REJgv3m_4vUXHAYzMGBLta8G3r7rVC-pFmAufNQrenmdyGhoi04sflhOz4lS8H404TzwGT205WG8F6uHcAQELiuswxiKHy_Ci3iiPOAeqDJZvrtmp7kjcV9fT34UxrTUs-Ucn38X1xL5mIufgZY1S5jTAL-eKPO"
          >
            Get Fruitcake
          </a>
        </Button>

        <h2 data-testid="title" className="mt-6">
          Login
        </h2>
        <form
          className="mb-4"
          onSubmit={(event) => {
            event.preventDefault();
            getAuthLink(formEmail);
          }}
        >
          <label className="block mb-2 text-sm" htmlFor="email">
            Enter your email to receive a login link
          </label>
          <div className="flex gap-3">
            <input
              ref={emailInputRef}
              id="email"
              data-testid="email-input"
              className="text-input w-full max-w-[240px]"
              type="email"
              placeholder="user@email.com"
              onChange={(event) => {
                event.preventDefault();
                setFormEmail(event.target.value);
              }}
            />
            <Button
              variant="CTA"
              dataTestId="submit-button"
              disabled={authState.fetching || csrfTokenLoading}
            >
              {authState.fetching ? 'SUBMITTING' : 'SUBMIT'}
            </Button>
          </div>
        </form>

        {authState.error && (
          <div className="text-red-400">
            We weren't able to log you in with that email address. If you've
            signed up before, please check for typos and try again, or sign up
            using the link above.
          </div>
        )}
      </div>
    </Dialog>
  );
};
