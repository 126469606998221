import { useState, useEffect } from 'react';
import { Charity, Payment, Donation } from '../../app-state/app-state';
import { CharityList } from '../charity-list/charity-list';

interface RecommendedCharitiesProps {
  charities: Charity[];
  donations: Payment[];
  currentDonationConfig: Donation[];
  recommendationCount?: number;
}

// Create a map to track the frequency of each category from the user's past donations
const buildCategoryMapFromDonations = (payments: Payment[]) => {
  const categoryMap = new Map<string, number>();

  payments.forEach((payment) => {
    if (payment.donation_config) {
      payment.donation_config.forEach((donation) => {
        donation.charity.categories.forEach((category) => {
          categoryMap.set(category, (categoryMap.get(category) || 0) + 1);
        });
      });
    }
  });

  return categoryMap;
};

// Calculate a score for each charity based on how many of its categories match the user's past donations
const calculateCharityScore = (
  charity: Charity,
  categoryMap: Map<string, number>,
) => {
  return charity.categories.reduce((score, category) => {
    return score + (categoryMap.get(category) || 0);
  }, 0);
};

// Generate a list of recommended charities, prioritizing those that match more categories
const getRecommendedCharities = (
  allCharities: Charity[],
  categoryMap: Map<string, number>,
  currentDonationConfig: Donation[],
  recommendationCount: number = 4,
) => {
  const currentCharityIds = new Set(
    currentDonationConfig.map((donation) => donation.charity.id),
  );

  // Filter charities based on category match and exclude those already being donated to
  const weightedCharities = allCharities
    .filter((charity) =>
      charity.categories.some((category) => categoryMap.has(category)),
    )
    .filter((charity) => !currentCharityIds.has(charity.id))
    .map((charity) => ({
      charity,
      score: calculateCharityScore(charity, categoryMap),
    }));

  const sortedCharities = weightedCharities.sort((a, b) => b.score - a.score);

  if (sortedCharities.length <= recommendationCount) {
    return sortedCharities.map((item) => item.charity);
  }

  // Select a number of top-scoring charities, adding some randomness for variety
  const selectedCharities = [];
  const highScoreCharities = sortedCharities.slice(0, recommendationCount);

  while (selectedCharities.length < recommendationCount) {
    const randomIndex = Math.floor(Math.random() * highScoreCharities.length);
    const charity = highScoreCharities.splice(randomIndex, 1)[0];
    selectedCharities.push(charity.charity);
  }

  return selectedCharities;
};

export const RecommendedCharities = ({
  charities,
  donations,
  currentDonationConfig,
  recommendationCount = 4,
}: RecommendedCharitiesProps) => {
  // storing the recommended charities in state to avoid recalculating on every render - this gives better ux
  const [recommended, setRecommended] = useState<Charity[]>([]);

  useEffect(() => {
    if (recommended.length === 0) {
      const categoryMap = buildCategoryMapFromDonations(donations);
      const recommendedCharities = getRecommendedCharities(
        charities,
        categoryMap,
        currentDonationConfig,
        recommendationCount,
      );
      setRecommended(recommendedCharities);
    }
  }, [
    charities,
    donations,
    currentDonationConfig,
    recommendationCount,
    recommended.length,
  ]);

  return (
    <div className="highlighted -mx-6 my-8 px-6 pt-4 pb-6">
      <h2 className="text-black dark:text-black">
        Recommended charities for you
      </h2>
      <p className="-mt-3 mb-6 text-sm text-black dark:text-black">
        Based on your past donations, you might be interested in these charities
      </p>
      <CharityList charities={recommended} showAddCharity={false} />
    </div>
  );
};
