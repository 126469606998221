import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFruitcakeStore } from '../../app-state/app-state';
import { routes } from '../../routes/routes';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';

const PaymentDetails = (paymentReference: string | undefined) => (
  <div className="my-6">
    <div className="max-w-2xl my-6 mx-auto overflow-hidden border border-gray-200 rounded-lg shadow-sm">
      <table className="min-w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>
            <td colSpan={2} className="px-4 py-2 whitespace-nowrap text-sm">
              Fruitcake Industries Ltd
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">
              Sort Code
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm">
              <code>04-00-03</code>
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">
              Account Number
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm">
              <code>15638381</code>
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">
              Payment reference
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm">
              {paymentReference}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <p>
      Please make sure to include your payment reference with each transaction,
      so we can match your donation to your settings.
    </p>
  </div>
);

const BackButton = ({ onClickAction }: { onClickAction: () => void }) => (
  <button
    onClick={onClickAction}
    className="absolute top-3 left-3 rounded-full bg-gray-100 p-2 text-gray-700 hover:bg-gray-200"
  >
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1 L3 6 L9 11"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
);

export const DonateDialog = () => {
  const {
    actions: { closeDonateDialog },
    appState: {
      isDonateDialogOpen,
      paymentReference,
      currentDonationConfig,
      appInfo,
    },
  } = useFruitcakeStore();

  const [view, setView] = useState('intro');
  const exampleDonationAmount = 10;
  const exampleFruitcakeFee =
    appInfo?.fruitcake_percentage &&
    BigNumber(exampleDonationAmount)
      .times(appInfo.fruitcake_percentage)
      .toFixed(0);

  // Dialog content based on the view state
  const dialogContent = () => {
    if (currentDonationConfig.length > 0) {
      switch (view) {
        case 'intro':
          return (
            <div data-testid="donate-dialog-intro">
              <p>
                You can make donations either as a one-off or recurring, and
                make them as often as you wish. When you donate, we check your
                current donation settings and split your donation accordingly.
              </p>
              <div className="flex justify-center gap-4 my-10">
                <Button onClick={() => setView('one-off')}>
                  Make a one-off donation
                </Button>
                <Button onClick={() => setView('regular')}>
                  Set up a regular donation
                </Button>
              </div>
              <div>
                {appInfo?.fruitcake_percentage && exampleFruitcakeFee && (
                  <p>
                    We take a {appInfo?.fruitcake_percentage}% fee on each
                    donation. This helps cover our costs and keeps Fruitcake
                    running smoothly. For example, if you donate £
                    {exampleDonationAmount}, our fee is just
                    {' ' + exampleFruitcakeFee}p. The rest goes straight to the
                    charities you choose.
                  </p>
                )}
              </div>
            </div>
          );
        case 'one-off':
          return (
            <div data-testid="donate-dialog-one-off">
              <BackButton onClickAction={() => setView('intro')} />
              <div data-testid="donate-dialog-one-off">
                <p>
                  To make a one-off donation, simply transfer your chosen amount
                  to Fruitcake's donation account. Make sure to include your
                  payment reference.
                </p>
                {PaymentDetails(paymentReference)}
              </div>
            </div>
          );
        case 'regular':
          return (
            <div data-testid="donate-dialog-regular">
              <BackButton onClickAction={() => setView('intro')} />
              <div data-testid="donate-dialog-regular">
                <p>
                  To set up a regular donation, please create a standing order.
                  We don't take your money (direct debit), you control the
                  payments. You can usually do it yourself using online or
                  mobile banking – often in a section called regular payments –
                  or you can contact your bank. Make sure to include your
                  payment reference.
                </p>
                {PaymentDetails(paymentReference)}
              </div>
            </div>
          );
        default:
          return null;
      }
    } else {
      return (
        <div className="mb-6">
          Ready to donate? Start by discovering and selecting your favourite
          charities here:
          <Link className="link" to={routes.DISCOVER}>
            Discover Charities
          </Link>
        </div>
      );
    }
  };

  return (
    <Dialog
      isOpen={isDonateDialogOpen}
      onClose={() => {
        setView('intro');
        closeDonateDialog();
      }}
      title="How to Donate"
    >
      <div className="max-w-3xl">{dialogContent()}</div>
    </Dialog>
  );
};
