import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import {
  sankey as d3Sankey,
  sankeyLinkHorizontal,
  SankeyNodeMinimal,
  SankeyLinkMinimal,
  SankeyGraph,
} from 'd3-sankey';
import { scaleOrdinal } from 'd3-scale';
import { interpolateRainbow } from 'd3-scale-chromatic';

interface CategoryWordData {
  text: string;
  value: number;
}

// Define a custom SankeyNode type that includes name
interface SankeyNodeExtra
  extends SankeyNodeMinimal<CategoryWordData, SankeyLinkExtra> {
  name: string;
}

// Define a custom SankeyLink type
interface SankeyLinkExtra
  extends SankeyLinkMinimal<SankeyNodeExtra, SankeyLinkExtra> {
  source: SankeyNodeExtra;
  target: SankeyNodeExtra;
}

interface ImpactSankeyDiagramProps {
  categoryWordData: CategoryWordData[];
  baseFontSize?: number; // Optional prop to set base font size
}

export const ImpactSankeyDiagram = ({
  categoryWordData,
  baseFontSize = 12, // Default font size set to 12px
}: ImpactSankeyDiagramProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!categoryWordData || categoryWordData.length === 0) return;

    const baseHeight = 340; // Base height for smaller datasets
    const additionalHeightPerNode = 20; // Additional height per node
    const numNodes = categoryWordData.length + 1; // +1 for the "Donor" node
    const height = baseHeight + additionalHeightPerNode * numNodes;

    const margin = { top: 10, right: 1, bottom: 10, left: 1 };
    const width = 700;
    const viewBoxWidth = 700;

    const svg = select(svgRef.current)
      .attr('viewBox', `0 0 ${viewBoxWidth} ${height}`)
      .attr('width', '100%')
      .attr('height', 'auto');

    const sankey = d3Sankey<SankeyNodeExtra, SankeyLinkExtra>()
      .nodeWidth(15)
      .nodePadding(10)
      .extent([
        [margin.left, margin.top],
        [width - margin.right, height - margin.bottom],
      ]);

    const colorRange = categoryWordData.map((_, i) =>
      interpolateRainbow(i / categoryWordData.length),
    );

    const color = scaleOrdinal<string, string>(colorRange).domain(
      categoryWordData.map((_, i) => String(i)),
    );

    // Define the nodes
    const nodes: SankeyNodeExtra[] = [
      { name: 'Donor', index: 0, sourceLinks: [], targetLinks: [] },
      ...categoryWordData.map((d, i) => ({
        name: `${d.text} (${d.value})`,
        index: i + 1,
        sourceLinks: [],
        targetLinks: [],
      })),
    ];

    // Find the Donor node to use as the source in links
    const donorNode = nodes.find((node) => node.name.startsWith('Donor'))!;

    // Define the links using direct node references
    const links: SankeyLinkExtra[] = categoryWordData.map((d) => ({
      source: donorNode,
      target: nodes.find((node) => node.name.startsWith(d.text))!,
      value: d.value,
    }));

    const sankeyData: SankeyGraph<SankeyNodeExtra, SankeyLinkExtra> = {
      nodes,
      links,
    };

    try {
      // Generate the sankey diagram
      const { nodes: sankeyNodes, links: sankeyLinks } = sankey(sankeyData);

      // Clear previous SVG content
      svg.selectAll('*').remove();

      // Create gradients for links
      const defs = svg.append('defs');

      sankeyLinks.forEach((link, i) => {
        const gradient = defs
          .append('linearGradient')
          .attr('id', `gradient-${i}`)
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', link.source.x1!)
          .attr('x2', link.target.x0!);

        gradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', color(String(link.source.index)));

        gradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', color(String(link.target.index)));
      });

      // Draw links with gradients
      svg
        .append('g')
        .attr('fill', 'none')
        .attr('stroke-opacity', 0.5)
        .selectAll('path')
        .data(sankeyLinks)
        .join('path')
        .attr('d', sankeyLinkHorizontal())
        .attr('stroke', (_, i) => `url(#gradient-${i})`)
        .attr('stroke-width', (d) => Math.max(1, d.width ?? 0));

      // Draw nodes
      svg
        .append('g')
        .selectAll('rect')
        .data(sankeyNodes)
        .join('rect')
        .attr('x', (d) => d.x0 ?? 0)
        .attr('y', (d) => d.y0 ?? 0)
        .attr('height', (d) => (d.y1 ?? 0) - (d.y0 ?? 0))
        .attr('width', (d) => (d.x1 ?? 0) - (d.x0 ?? 0))
        .attr('fill', (d) => color(String(d.index)))
        .attr('stroke', '#000')
        .attr('stroke-width', '1px');

      // Draw node labels - place this last so it appears on top
      svg
        .append('g')
        .selectAll('text')
        .data(sankeyNodes)
        .join('text')
        .attr('class', 'sankey-label-text')
        .attr('x', (d) => (d.x0 ?? 0) - 10)
        .attr('y', (d) => ((d.y1 ?? 0) + (d.y0 ?? 0)) / 2)
        .attr('dy', '0.35em')
        .attr('text-anchor', 'end')
        .attr('fill', '#000')
        .attr('font-size', `${baseFontSize}px`)
        .text((d) => d.name)
        .attr('pointer-events', 'none'); // Ensure the text is not affected by pointer events

      // Add "Your Donations" label
      svg
        .append('text')
        .attr('x', (donorNode.x0 ?? 0) + 25)
        .attr('y', (donorNode.y1 ?? 0) / 2)
        .attr('dy', '0.35em')
        .attr('class', 'sankey-label-text')
        .attr('fill', '#000')
        .attr('font-size', `${baseFontSize + 2}px`)
        .text('Your Donations')
        .attr('pointer-events', 'none');

      // Function to update text size based on the container's width
      const updateTextSize = () => {
        const actualWidth = svgRef.current?.clientWidth || 0;
        const scaleFactor = actualWidth / viewBoxWidth;

        svg
          .selectAll('text')
          .attr('font-size', `${baseFontSize / scaleFactor}px`);
      };

      // Initial adjustment and on window resize
      updateTextSize();
      window.addEventListener('resize', updateTextSize);

      return () => {
        window.removeEventListener('resize', updateTextSize);
      };
    } catch (error) {
      console.error('Error during Sankey processing:', error);
    }
  }, [categoryWordData, baseFontSize]);

  return (
    <div className="animate-hue-rotate">
      <h2>Charity categories you support</h2>
      <p className="text-sm">
        This chart shows the number of times a charity category has appeared in
        your donations. It's a great way to see the causes you care about the
        most!
      </p>
      <div className="-mt-3 -hue-rotate-[40deg]">
        <svg ref={svgRef}></svg>
      </div>
    </div>
  );
};
