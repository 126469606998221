import { useEffect } from 'react';
import { useFruitcakeStore } from '../../app-state/app-state';
import { Button } from '../../components/button/button';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons';

export const CheckEmail = () => {
  const {
    appState: { email, emailLinkSent },
    actions: { openLoginDialog },
  } = useFruitcakeStore();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('useEffect fired');
    console.log('email:', email);
    if (email) {
      console.log('Navigating to DISCOVER');
      navigate(routes.DISCOVER);
    }
  }, [email, navigate]);

  return (
    <div>
      {emailLinkSent ? (
        <>
          <div className="flex items-center gap-4 mb-6">
            <h1 className="m-0">Login link sent</h1>
            <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
          </div>

          <p>
            We've sent a login link to your email address. Please check your
            inbox and click the link to log in.
          </p>
          <ol className="list-decimal pl-8 my-6">
            <li>Open your email inbox.</li>
            <li>
              Look for an email from <strong>hello@fruitcakeapp.co.uk</strong>.
            </li>
            <li>Click the login link to access your account.</li>
          </ol>
          <p>If you don't see the email, check your spam or junk folder.</p>
          <p>
            If you still don't see the email, you can request a new login link
            by clicking the button below.
          </p>
          <Button onClick={openLoginDialog}>Login / signup</Button>
        </>
      ) : (
        <div>
          <h1>Try logging in again</h1>
          <p>
            To send a login link to your email address, use the button below
          </p>
          <Button onClick={openLoginDialog} variant="CTA">
            Login / signup
          </Button>
        </div>
      )}
    </div>
  );
};
