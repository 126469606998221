import { ReactNode } from 'react';
import { Loader } from '../loader/loader';

interface DataLoaderProps<T> {
  data: T | undefined;
  noDataMessage?: string;
  loading: boolean;
  error: Error | undefined | null;
  errorMessage?: ReactNode;
  render?: (data: T) => ReactNode;
}

export function DataLoader<T = object>({
  data,
  noDataMessage,
  loading,
  error,
  errorMessage,
  render,
}: DataLoaderProps<T>) {
  if (error) {
    return <div>{errorMessage ? errorMessage : `Error: ${error.message}`}</div>;
  }

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return <div>{noDataMessage ? noDataMessage : 'No data'}</div>;
  }

  return <>{render ? render(data as T) : null}</>;
}
