import { useEffect } from 'react';
import { useFruitcakeStore } from '../app-state/app-state';

export const useCsrfToken = () => {
  const { csrfToken, csrfTokenLoading } = useFruitcakeStore(
    (state) => state.appState
  );

  useEffect(() => {
    if (!csrfToken && !csrfTokenLoading) {
      useFruitcakeStore.getState().actions.fetchAndSetCsrfToken();
    }
  }, [csrfToken, csrfTokenLoading]);

  return { csrfToken, csrfTokenLoading };
};
