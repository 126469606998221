import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { LoggedInRoutesConfig, LoggedOutRoutesConfig } from './routes-config';
import { useFruitcakeStore } from '../app-state/app-state';
import { RouteErrorBoundary } from './route-error-boundary';
import { Loader } from '../components/loader/loader';

export const AppRouter = () => {
  const { appState } = useFruitcakeStore();
  const isLoggedIn = !!appState.email;
  const routes = useRoutes(
    isLoggedIn ? LoggedInRoutesConfig : LoggedOutRoutesConfig
  );

  return (
    <RouteErrorBoundary>
      <Suspense fallback={<Loader />}>{routes}</Suspense>
    </RouteErrorBoundary>
  );
};
