import { Link } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { Charity, Donation } from '../../app-state/app-state';
import { CharityAdd } from '../charity-add/charity-add';

export const checkHasOtherDonations = (
  currentDonationConfig: Donation[],
  charityId: string,
) => {
  return Boolean(
    currentDonationConfig.filter(
      (donation) => donation.charity.id !== charityId,
    ).length,
  );
};

export const checkIsDonatingToCharity = (
  currentDonationConfig: Donation[],
  charityId: string,
) => {
  return currentDonationConfig.filter(
    (donation) => donation.charity.id === charityId,
  )[0];
};

const linkStyles =
  'w-full py-3 px-4 inline-flex flex-col justify-center items-center gap-0 font-medium shadow-sm align-middle transition-all text-sm sm:p-4 dark:border-gray-700';

interface CharityListItemProps {
  charity: Charity;
}

export const CharityListItem = ({ charity }: CharityListItemProps) => {
  return (
    <>
      <div className="h-44 flex flex-col justify-center items-center rounded-t-xl bg-white dark:opacity-90">
        <img
          className="max-h-[140px] max-w-[140px]"
          src={charity.logo_url}
          alt={charity.name}
        />
      </div>
      <div className="p-4 md:p-6">
        <h3 className="text-xl font-semibold">{charity.name}</h3>

        <div className="relative max-h-20 overflow-hidden">
          <p className="mt-3 text-gray-500">
            {charity.description.substring(0, 200)}
          </p>
          <div className="absolute z-0 bottom-0 left-0 right-0 h-8 pointer-events-none bg-gradient-to-t from-white dark:from-slate-900 to-transparent"></div>
        </div>
      </div>
      <div className="mt-auto grid grid-cols-2 border-t border-gray-200 divide-x divide-gray-200 dark:border-gray-700 dark:divide-gray-700">
        <Link to={`${routes.DISCOVER}/${charity.id}`} className={linkStyles}>
          Learn more
        </Link>

        <CharityAdd charity={charity} />
      </div>
    </>
  );
};
