import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';
import { Charity, useFruitcakeStore } from '../../app-state/app-state';
import { CharityList } from '../../components/charity-list/charity-list';
import { RecommendedCharities } from '../../components/recommended-charities/recommended-charities';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const constructCharityCategories = (charities: Charity[]) => {
  return charities.reduce((acc, charity) => {
    charity.categories.forEach((category) => {
      if (!acc.some((cat) => cat === category)) {
        acc.push(category);
      }
    });
    return acc.sort((a, b) => a.localeCompare(b));
  }, [] as string[]);
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const Discover = () => {
  const {
    charities,
    charitiesLoading,
    donationsList,
    currentDonationConfig,
    donationsLoading,
  } = useFruitcakeStore((state) => state.appState);
  const navigate = useNavigate();
  const query = useQuery();

  const initialCategories = query.get('categories')
    ? query.get('categories')!.split(',')
    : [];
  const initialSearch = query.get('search') || '';

  const [searchTerm, setSearchTerm] = useState<string>(initialSearch);
  const [filteredCharities, setFilteredCharities] = useState<Charity[]>([]);
  const [availableCharityCategories, setAvailableCharityCategories] = useState<
    string[]
  >(constructCharityCategories(charities));
  const [selectedCategories, setSelectedCategories] =
    useState<string[]>(initialCategories);

  const fuseByName = useMemo(
    () =>
      new Fuse(charities, {
        keys: ['name'],
        includeScore: true,
      }),
    [charities],
  );

  useEffect(() => {
    let results = charities;

    if (searchTerm.trim()) {
      const resultsByName = fuseByName.search(searchTerm);
      results = resultsByName.map((item) => item.item);
    }

    if (selectedCategories.length > 0) {
      results = results.filter((charity) =>
        selectedCategories.every((category) =>
          charity.categories.includes(category),
        ),
      );
    }

    setFilteredCharities(results);
    setAvailableCharityCategories(constructCharityCategories(results));
  }, [searchTerm, selectedCategories, charities, fuseByName]);

  const handleCategoryClick = (categoryName: string) => {
    const newCategories = selectedCategories.includes(categoryName)
      ? selectedCategories.filter((cat) => cat !== categoryName)
      : [...selectedCategories, categoryName];
    setSelectedCategories(newCategories);
    updateQueryParams(newCategories, searchTerm);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    updateQueryParams(selectedCategories, newSearchTerm);
  };

  const updateQueryParams = (categories: string[], search: string) => {
    const params = new URLSearchParams();
    if (categories.length > 0) {
      params.set('categories', categories.join(','));
    }
    if (search.trim()) {
      params.set('search', search);
    }
    navigate({ search: params.toString() }, { replace: true });
  };

  return (
    <>
      <h1>Discover charities</h1>
      <div className="grid lg:grid-cols-[1fr_auto] gap-6 mb-6">
        <div className="flex gap-2 flex-wrap self-start">
          {availableCharityCategories.map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryClick(category)}
              className="cursor-pointer inline-flex items-center gap-2 px-2 py-1 text-xs font-semibold text-gray-700 bg-gray-200 rounded-lg"
            >
              <span className="border border-transparent">{category}</span>
              {selectedCategories.includes(category) && (
                <div className="flex items-center justify-center border border-gray-500 bg-gray-300 w-4 h-4 rounded-full leading-none">
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              )}
            </button>
          ))}
        </div>

        <div className="self-start">
          <input
            placeholder="Filter by text"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="block w-[260px] max-w-md mb-4 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      {!donationsLoading &&
        donationsList.length > 0 &&
        selectedCategories.length === 0 &&
        !searchTerm && (
          <RecommendedCharities
            charities={charities}
            donations={donationsList}
            currentDonationConfig={currentDonationConfig}
            recommendationCount={4}
          />
        )}

      <CharityList
        charities={filteredCharities}
        charitiesLoading={charitiesLoading}
      />
    </>
  );
};
