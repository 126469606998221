import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretLeft,
  faCaretRight,
  faSpinner,
  faCloudArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { createSlidersData } from '../../utils/create-sliders-data';
import { getDonationsDataFromSlidersData } from '../../utils/get-donations-data-from-sliders-data';
import { Slider, SliderDataProps } from '../slider/slider';
import { Button } from '../button/button';
import { useFruitcakeStore } from '../../app-state/app-state';
import type { Donation } from '../../app-state/app-state';

export enum SliderActions {
  LOCK = 'lock',
  UNLOCK = 'unlock',
  CHANGE_VALUE = 'change-value',
  REMOVE = 'remove',
  START = 'start',
  END = 'end',
}

interface SlidersProps {
  donations: Donation[];
}

const TOTAL_VALUE = 100;
const UNDO_REDO_STACK_LIMIT = 20;

export const Sliders = ({ donations }: SlidersProps) => {
  const [slidersData, setSlidersData] = useState<SliderDataProps[]>(
    createSlidersData(donations),
  );
  const [availableTotal, setAvailableTotal] = useState(TOTAL_VALUE);
  const [undoStack, setUndoStack] = useState<SliderDataProps[][]>([]);
  const [redoStack, setRedoStack] = useState<SliderDataProps[][]>([]);
  const { adjustDonationConfig, clearCurrentDonationToHighlight } =
    useFruitcakeStore((state) => state.actions);
  const { currentDonationToHighlight } = useFruitcakeStore(
    (state) => state.appState,
  );
  const preAdjustmentStateRef = useRef<SliderDataProps[] | null>(null);
  const [isSavingToServer, setIsSavingToServer] = useState(false);
  const savingTimerRef = useRef<number | null>(null);
  const listRefs = useRef<(HTMLLIElement | null)[]>([]);
  const hasScrolled = useRef(false);
  const [flashCharityIds, setFlashCharityIds] = useState<string[]>([]);

  // When sliders' state changes, write the donations data to app state (this will trigger writing to the server)
  useEffect(() => {
    const slidersStateSnapshot = cloneDeep(slidersData);

    const requestTimeout = setTimeout(() => {
      const slidersStateHasNotChanged = isEqual(
        slidersStateSnapshot,
        slidersData,
      );

      if (slidersStateHasNotChanged) {
        const saveDonations = async () => {
          setIsSavingToServer(true);
          adjustDonationConfig(
            getDonationsDataFromSlidersData(slidersData),
            true,
          );
          if (savingTimerRef.current) {
            clearTimeout(savingTimerRef.current);
          }
          savingTimerRef.current = window.setTimeout(() => {
            setIsSavingToServer(false);
          }, 600);
        };

        saveDonations().catch((error) => {
          console.error('Error saving donations:', error);
          setIsSavingToServer(false);
        });
      }
    }, 500);

    return () => clearTimeout(requestTimeout);
  }, [slidersData, adjustDonationConfig]);

  useEffect(() => {
    if (!hasScrolled.current) {
      if (currentDonationToHighlight) {
        // Find the charity that matches the charityId from the URL
        const matchingSlider = slidersData.find(
          (data) => data.charity.id === currentDonationToHighlight,
        );

        if (matchingSlider) {
          setFlashCharityIds([matchingSlider.charity.id]);
          console.log('flashCharityIds', flashCharityIds);
          // Scroll to the matching slider
          const index = slidersData.findIndex(
            (data) => data.charity.id === currentDonationToHighlight,
          );
          if (index !== -1) {
            listRefs.current[index]?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }

          clearCurrentDonationToHighlight();
          hasScrolled.current = true; // Ensure the effect doesn't run again
          return;
        }
      }

      // If no charityId or no match, find all sliders with 0% percentage
      if (flashCharityIds.length === 0) {
        const zeroPercentCharities = slidersData.filter(
          (data) => data.percentage === 0,
        );
        if (zeroPercentCharities.length > 0) {
          setFlashCharityIds(
            zeroPercentCharities.map((data) => data.charity.id),
          );
          // Scroll to the first 0% slider if there is one
          const firstZeroPercentIndex = slidersData.findIndex(
            (data) => data.percentage === 0,
          );
          if (firstZeroPercentIndex !== -1) {
            listRefs.current[firstZeroPercentIndex]?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }
      }

      hasScrolled.current = true;
    }
  }, [
    currentDonationToHighlight,
    slidersData,
    flashCharityIds,
    clearCurrentDonationToHighlight,
  ]);

  const handleSliderAction = (
    action: SliderActions,
    sliderData: SliderDataProps,
  ) => {
    switch (action) {
      case SliderActions.START:
        preAdjustmentStateRef.current = cloneDeep(slidersData);
        break;
      case SliderActions.END:
        const preAdjustmentState = preAdjustmentStateRef.current;
        const postAdjustmentState = cloneDeep(slidersData);

        if (
          preAdjustmentState &&
          !isEqual(preAdjustmentState, postAdjustmentState)
        ) {
          setUndoStack((prevStack) => {
            const newStack = [preAdjustmentState, ...prevStack];
            return newStack.length > UNDO_REDO_STACK_LIMIT
              ? newStack.slice(0, UNDO_REDO_STACK_LIMIT)
              : newStack;
          });
          setRedoStack([]);
        }
        break;
      case SliderActions.LOCK:
        captureInitialState();
        lockSlider(sliderData.id);
        break;
      case SliderActions.UNLOCK:
        captureInitialState();
        unlockSlider(sliderData.id);
        break;
      case SliderActions.CHANGE_VALUE:
        changeSliderValue(sliderData.id, sliderData.percentage);
        break;
      case SliderActions.REMOVE:
        captureInitialState();
        removeSlider(sliderData.id);
        break;
      default:
        break;
    }
  };

  const captureInitialState = useCallback(() => {
    setUndoStack((prevStack) => {
      const newStack = [cloneDeep(slidersData), ...prevStack];
      return newStack.length > UNDO_REDO_STACK_LIMIT
        ? newStack.slice(0, UNDO_REDO_STACK_LIMIT)
        : newStack;
    });
    setRedoStack([]);
  }, [slidersData]);

  const unlockedSliders = useMemo(
    () => slidersData.filter(({ sliderLocked }) => !sliderLocked),
    [slidersData],
  );

  const lockedSliders = useMemo(
    () => slidersData.filter(({ sliderLocked }) => sliderLocked),
    [slidersData],
  );

  const lockSlider = (changedSliderId: string) => {
    const sliderToLock = slidersData.filter(
      ({ id }) => id === changedSliderId,
    )[0];

    if (!sliderToLock || sliderToLock.sliderLocked) {
      return;
    }

    // Remove the value of the slider from the availableTotal, making that amount unavailable to the other sliders
    setAvailableTotal((prevState) => prevState - sliderToLock.percentage);

    setSlidersData((prevState) => {
      const remainingUnlockedSliders = prevState.filter(
        ({ id, sliderLocked }) => !sliderLocked && id !== changedSliderId,
      );

      if (remainingUnlockedSliders.length <= 2) {
        return prevState.map((sliderData) => {
          const { id, sliderLocked } = sliderData;

          if (id === changedSliderId) {
            return {
              ...sliderData,
              sliderLocked: true,
            };
          }

          if (sliderLocked) {
            return sliderData;
          }

          return {
            ...sliderData,
            sliderLockDisabled: true,
          };
        });
      } else {
        return prevState.map((sliderData) => {
          const { id } = sliderData;

          if (id === changedSliderId) {
            return {
              ...sliderData,
              sliderLocked: true,
              sliderLockDisabled: false,
            };
          }

          return {
            ...sliderData,
            sliderLockDisabled: false,
          };
        });
      }
    });
  };

  const unlockSlider = (changedSliderId: string) => {
    const sliderToUnlock = slidersData.filter(
      ({ id }) => id === changedSliderId,
    )[0];

    if (!sliderToUnlock || !sliderToUnlock.sliderLocked) {
      return;
    }

    // Add the value of the slider back to the availableTotal
    setAvailableTotal((prevState) => prevState + sliderToUnlock.percentage);

    setSlidersData((prevState) =>
      prevState.map((sliderData) => {
        const { id } = sliderData;

        if (id === changedSliderId) {
          return {
            ...sliderData,
            sliderLocked: false,
            sliderLockDisabled: false,
          };
        }

        // If a slider is being unlocked, we can ensure all other toggles are unlocked
        return {
          ...sliderData,
          sliderLockDisabled: false,
        };
      }),
    );
  };

  const changeSliderValue = (
    changedSliderId: string,
    changedSliderValue: number,
  ) => {
    const sliderToChange = slidersData.filter(
      ({ id }) => id === changedSliderId,
    )[0];

    if (!sliderToChange) {
      return;
    }

    setSlidersData((prevState) => {
      // we want to calculate the difference between the new value and the old value
      const difference =
        prevState.filter(({ id }) => id === changedSliderId)[0].percentage -
        changedSliderValue;

      const { otherUnlockedSliders, otherUnlockedSlidersTotal } =
        prevState.reduce(
          (acc, sliderData) => {
            if (sliderData.sliderLocked || sliderData.id === changedSliderId) {
              return acc;
            }

            return {
              otherUnlockedSliders: [...acc.otherUnlockedSliders, sliderData],
              otherUnlockedSlidersTotal:
                acc.otherUnlockedSlidersTotal + sliderData.percentage,
            };
          },
          {
            otherUnlockedSliders: [] as SliderDataProps[],
            otherUnlockedSlidersTotal: 0,
          },
        );

      return prevState.map((sliderData) => {
        if (sliderData.sliderLocked) {
          return sliderData;
        }

        if (sliderData.id === changedSliderId) {
          return {
            ...sliderData,
            percentage: changedSliderValue,
          };
        }

        // we now want to give each of the other unlocked sliders a proportion of the difference equal to the percentage of the total that it represents.
        // watch out for divide by 0 errors!
        const portionOfUnlockedTotal =
          otherUnlockedSlidersTotal === 0 || sliderData.percentage === 0
            ? 0
            : sliderData.percentage / otherUnlockedSlidersTotal;

        let newValue;

        if (portionOfUnlockedTotal === 0) {
          if (otherUnlockedSlidersTotal === 0) {
            // In this case, none of the other unlocked sliders have any value, so we can spread the difference between them equally
            newValue = difference / otherUnlockedSliders.length;
          } else {
            // Otherwise, keep this value at 0 and let the other sliders take the difference
            newValue = 0;
          }
        } else {
          newValue =
            sliderData.percentage + portionOfUnlockedTotal * difference;
        }

        return {
          ...sliderData,
          percentage: newValue,
        };
      });
    });
  };

  const removeSlider = (SliderId: string) => {
    const sliderToRemove = slidersData.filter(({ id }) => id === SliderId)[0];

    if (!sliderToRemove) {
      return;
    }

    if (sliderToRemove.sliderLocked) {
      // If the slider being removed had been locked (thus removing its value from the available total), then the
      // available total needs to be updated with the value to be removed.
      setAvailableTotal((prevState) => prevState + sliderToRemove.percentage);
    }

    const remaining = slidersData.filter(({ id }) => id !== SliderId);

    const unlockedRemainingSliders = remaining.filter(
      ({ sliderLocked }) => !sliderLocked,
    );

    const sliderLockDisabledRemainingSliders = remaining.filter(
      ({ sliderLockDisabled }) => sliderLockDisabled,
    );

    setSlidersData(() =>
      remaining.map((sliderData) => {
        const { percentage, sliderLocked, sliderLockDisabled } = sliderData;
        // We need to determine whether the lock button should be disabled for this slider after the removal
        // of the slider being removed. This should adjust in certain conditions. If only two sliders remain,
        // the lock should be disabled, as we don't want the user to be able to lock one of only two sliders. If that
        // scenario isn't true, and only one disabled lock remains we should enable it. Otherwise, we can keep the
        // disabled state as it was.
        const shouldLockBeDisabled =
          remaining.length <= 2
            ? true
            : sliderLockDisabledRemainingSliders.length === 1
              ? false
              : sliderLockDisabled;

        if (sliderLocked) {
          // This slider has previously been locked, but we now need to determine if it should still be. If there are
          // only two remaining sliders, it should be unlocked.
          const sliderShouldBeUnlocked = remaining.length <= 2;

          return {
            ...sliderData,
            // If the slider is to remain locked, we'll not adjust its percentage. If it's to be unlocked, we'll
            // adjust it to receive an equal portion of the percentage held by the slider being removed.
            sliderLocked: !sliderShouldBeUnlocked,
            sliderLockDisabled: shouldLockBeDisabled,
          };
        }

        const newValue =
          percentage +
          sliderToRemove.percentage / unlockedRemainingSliders.length;

        return {
          ...sliderData,
          percentage: newValue,
          sliderLockDisabled: shouldLockBeDisabled,
        };
      }),
    );
  };

  const spreadAllDonationsEvenly = useCallback(() => {
    captureInitialState();
    setAvailableTotal(() => TOTAL_VALUE);
    setSlidersData((prevState) =>
      prevState.map((sliderData) => {
        return {
          ...sliderData,
          percentage: TOTAL_VALUE / prevState.length,
          sliderLocked: false,
          sliderLockDisabled: prevState.length <= 2,
        };
      }),
    );
  }, [captureInitialState]);

  const areDonationsSpreadEvenly = useMemo(() => {
    return slidersData.every(
      ({ percentage }) => percentage === TOTAL_VALUE / slidersData.length,
    );
  }, [slidersData]);

  const unlockAllDonations = useCallback(() => {
    captureInitialState();
    setSlidersData((prevState) =>
      prevState.map((sliderData) => {
        return {
          ...sliderData,
          sliderLocked: false,
          sliderLockDisabled: prevState.length <= 2,
        };
      }),
    );
  }, [captureInitialState]);

  const spreadUnlockedDonationsEvenly = useCallback(() => {
    captureInitialState();
    setSlidersData((prevState) =>
      prevState.map((sliderData) => {
        if (sliderData.sliderLocked) {
          return sliderData;
        }

        return {
          ...sliderData,
          percentage: availableTotal / unlockedSliders.length,
        };
      }),
    );
  }, [availableTotal, unlockedSliders.length, captureInitialState]);

  const areUnlockedDonationsSpreadEvenly = useMemo(() => {
    return unlockedSliders.every(
      ({ percentage }) =>
        percentage === availableTotal / unlockedSliders.length,
    );
  }, [availableTotal, unlockedSliders]);

  const undo = () => {
    setUndoStack((prevStack) => {
      if (prevStack.length === 0) return prevStack;
      const [previousState, ...rest] = prevStack;
      setRedoStack((currRedo) => {
        if (currRedo.length === 0 || !isEqual(slidersData, currRedo[0])) {
          const newRedoStack = [cloneDeep(slidersData), ...currRedo];
          return newRedoStack.length > UNDO_REDO_STACK_LIMIT
            ? newRedoStack.slice(0, UNDO_REDO_STACK_LIMIT)
            : newRedoStack;
        }
        return currRedo;
      });
      setSlidersData(previousState);
      return rest;
    });
  };

  const redo = () => {
    setRedoStack((prevStack) => {
      if (prevStack.length === 0) return prevStack;
      const [nextState, ...rest] = prevStack;
      setUndoStack((currUndo) => {
        if (currUndo.length === 0 || !isEqual(slidersData, currUndo[0])) {
          const newUndoStack = [cloneDeep(slidersData), ...currUndo];
          return newUndoStack.length > UNDO_REDO_STACK_LIMIT
            ? newUndoStack.slice(0, UNDO_REDO_STACK_LIMIT)
            : newUndoStack;
        }
        return currUndo;
      });
      setSlidersData(nextState);
      return rest;
    });
  };

  return (
    <div data-testid="sliders-wrapper">
      <div className="flex flex-col md:flex-row-reverse md:items-center justify-between">
        <div className="w-[210px] h-[36px] flex justify-center border border-gray-300 dark:border-gray-500 rounded-full text-sm text-gray-600 dark:text-gray-400">
          {isSavingToServer &&
          (undoStack.length > 0 || redoStack.length > 0) ? (
            <div className="flex items-center gap-2 animate-fadeInOut">
              <div className="animate-spin">
                <FontAwesomeIcon icon={faSpinner} />
              </div>
              <span>Saving...</span>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faCloudArrowUp} />{' '}
              <span>Changes are autosaved</span>
            </div>
          )}
        </div>

        <div className="flex my-6 gap-4 text-sm">
          <Button
            variant="default"
            onClick={undo}
            disabled={undoStack.length === 0}
            padding="small"
          >
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faCaretLeft} />
              <span>Undo</span>
            </div>
          </Button>

          <Button
            variant="default"
            onClick={redo}
            disabled={redoStack.length === 0}
            padding="small"
          >
            <div className="flex items-center gap-2">
              <span>Redo</span>
              <FontAwesomeIcon icon={faCaretRight} />
            </div>
          </Button>
        </div>
      </div>

      {lockedSliders.length > 0 && slidersData.length > 2 ? (
        <div className="flex mb-6 justify-between gap-4 text-sm">
          <Button
            variant="default"
            onClick={spreadUnlockedDonationsEvenly}
            disabled={areUnlockedDonationsSpreadEvenly}
          >
            Spread unlocked donations evenly
          </Button>

          <Button variant="default" onClick={unlockAllDonations}>
            Unlock all donations
          </Button>
        </div>
      ) : (
        <div className="mb-6 text-sm">
          <Button
            variant="default"
            onClick={spreadAllDonationsEvenly}
            disabled={areDonationsSpreadEvenly}
          >
            Spread all donations evenly
          </Button>
        </div>
      )}

      <ul data-testid="sliders-list" className="grid lg:grid-cols-2 gap-6">
        {slidersData.map((data, i) => (
          <li key={i} ref={(el) => (listRefs.current[i] = el)}>
            <Slider
              id={data.id}
              totalValue={TOTAL_VALUE}
              maxValue={availableTotal}
              sliderData={data}
              handleSliderAction={handleSliderAction}
              flash={flashCharityIds.includes(data.charity.id)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
