import { useFruitcakeStore } from '../../app-state/app-state';
import { XMarkIcon } from '@heroicons/react/20/solid';

export const ErrorMessageBanner = () => {
  const appState = useFruitcakeStore((state) => state.appState);
  const actions = useFruitcakeStore((state) => state.actions);

  return (
    <div className="relative z-50">
      <div className="w-full">
        {appState.errorToDisplay && (
          <>
            <div className="flex items-center gap-x-6 bg-red-400 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
              <p className="m-1 text-md leading-6 text-white">
                {appState.errorToDisplay}
              </p>
              <div className="flex flex-1 justify-end">
                <button
                  type="button"
                  className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                  onClick={() => actions.clearError()}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
