import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFruitcakeStore } from '../../app-state/app-state';
import { Logo } from '../header-logo/logo';
import { HeaderNavItems } from '../header-nav-items/header-nav-items';
import { Button } from '../button/button';

export const Header = () => {
  const {
    actions: { openDonateDialog, openLoginDialog },
    appState: { email },
  } = useFruitcakeStore();

  const isLoggedIn = !!email;
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const navbarCollapse = document.getElementById(
        'navbar-collapse-with-animation',
      );
      if (navbarCollapse && !navbarCollapse.classList.contains('hidden')) {
        navbarCollapse.classList.add('hidden');
        setMenuOpen(false);
      }
    };

    const handleNavigation = () => {
      const navbarCollapse = document.getElementById(
        'navbar-collapse-with-animation',
      );
      if (navbarCollapse && !navbarCollapse.classList.contains('hidden')) {
        navbarCollapse.classList.add('hidden');
        setMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Trigger handleNavigation on route change
    handleNavigation();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  const toggleMenu = () => {
    const navbarCollapse = document.getElementById(
      'navbar-collapse-with-animation',
    );
    if (navbarCollapse) {
      if (navbarCollapse.classList.contains('hidden')) {
        navbarCollapse.classList.remove('hidden');
        setMenuOpen(true);
      } else {
        navbarCollapse.classList.add('hidden');
        setMenuOpen(false);
      }
    }
  };

  return (
    <div className="sticky top-0 z-10">
      <header
        data-testid="header"
        className="flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full bg-white border-b border-gray-200 text-sm py-3 dark:bg-gray-800 dark:border-gray-700"
      >
        <div className="w-full max-w-8xl mx-auto">
          <nav
            className="relative w-full mx-auto pl-4 grid grid-cols-[1fr_repeat(2,auto)] items-center gap-4"
            aria-label="Global"
          >
            <Logo />

            <div className="md:hidden">
              <button
                type="button"
                className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                onClick={toggleMenu}
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                <svg
                  className={!menuOpen ? 'block w-4 h-4' : 'hidden'}
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
                <svg
                  className={menuOpen ? 'block w-4 h-4' : 'hidden'}
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>

            <div
              id="navbar-collapse-with-animation"
              className="z-50 hs-collapse hidden overflow-hidden basis-full grow absolute top-[46px] right-0 rounded-bl bg-white border border-t-0 border-gray-200 text-sm dark:bg-gray-800 dark:border-gray-700 md:block md:static md:border-0"
            >
              <div className="flex flex-col md:flex-row md:gap-3 p-4 md:py-0">
                <HeaderNavItems />

                {isLoggedIn ? (
                  <div
                    data-testid="header-donate-button"
                    className="mt-3 md:mt-0 md:ml-3 md:inline-block"
                  >
                    <Button
                      onClick={openDonateDialog}
                      variant="CTA"
                      padding="small"
                    >
                      Donate now
                    </Button>
                  </div>
                ) : (
                  <div
                    data-testid="header-login-button"
                    className="mt-3 md:mt-0 md:ml-3 md:inline-block"
                  >
                    <Button
                      onClick={openLoginDialog}
                      variant="CTA"
                      padding="small"
                    >
                      Login / signup
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};
