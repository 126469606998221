import React, { useRef, useEffect, useState } from 'react';
import { select } from 'd3-selection';
import { scaleOrdinal } from 'd3-scale';
import { pie, arc, PieArcDatum } from 'd3-shape';
import { interpolateTurbo } from 'd3-scale-chromatic';
import { interpolate } from 'd3-interpolate';
import 'd3-transition';

import { CharityDonationData } from '../../utils/use-impact-data';

interface ImpactDonutDonationAmountsProps {
  data: CharityDonationData[];
  baseLogoSize?: number;
}

export const ImpactDonutDonationAmounts = ({
  data,
  baseLogoSize = 52,
}: ImpactDonutDonationAmountsProps) => {
  const chartRef = useRef<SVGSVGElement>(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  const renderChart = (
    dataToRender: CharityDonationData[],
    animate: boolean,
  ) => {
    if (!chartRef.current) return;

    const svg = select(chartRef.current);

    const viewBoxSize = 600;
    const radius = viewBoxSize * 0.8;
    const logoOffset = 360;

    const sortedData = [...dataToRender].sort((a, b) => b.amount - a.amount);

    const distributedData: CharityDonationData[] = [];
    while (sortedData.length) {
      if (sortedData.length > 1) {
        distributedData.push(sortedData.shift()!);
        distributedData.push(sortedData.pop()!);
      } else {
        distributedData.push(sortedData.pop()!);
      }
    }

    const color = scaleOrdinal<string>()
      .domain(distributedData.map((d) => d.name))
      .range(
        distributedData.map((_, i) =>
          interpolateTurbo(i / distributedData.length),
        ),
      );

    svg.selectAll('*').remove();

    const g = svg
      .attr('width', '100%')
      .attr('height', 'auto')
      .attr('viewBox', `0 0 ${viewBoxSize} ${viewBoxSize}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', `translate(${viewBoxSize / 2},${viewBoxSize / 2})`);

    const pieGenerator = pie<CharityDonationData>()
      .value((d) => d.amount)
      .sort(null);

    const initialData = distributedData.map((item) => ({ ...item, amount: 0 }));
    const initialDataReady = pieGenerator(initialData);
    const dataReady = pieGenerator(distributedData);

    const arcGenerator = arc<PieArcDatum<CharityDonationData>>()
      .innerRadius(radius * 0.25)
      .outerRadius(radius * 0.35);

    const paths = g
      .selectAll<SVGPathElement, PieArcDatum<CharityDonationData>>('path')
      .data(animate ? initialDataReady : dataReady)
      .join('path')
      .attr('fill', (d) => color(d.data.name) as string)
      .attr('stroke', 'white')
      .style('stroke-width', '2px')
      .style('opacity', 0.7)
      .attr('d', arcGenerator);

    if (animate) {
      paths
        .data(dataReady)
        .transition()
        .duration(1000)
        .attrTween('d', function (d, i) {
          const interpolateStart = interpolate(initialDataReady[i], d);
          return (t) => {
            const interpolatedData = interpolateStart(t);
            const path = arcGenerator(interpolatedData)!;
            updateAssociatedElements(interpolatedData, i);
            return path;
          };
        });
    } else {
      paths.each(function (d, i) {
        updateAssociatedElements(d, i);
      });
    }

    function updateAssociatedElements(
      interpolatedData: PieArcDatum<CharityDonationData>,
      i: number,
    ) {
      const [x1, y1] = arcGenerator.centroid(interpolatedData);
      const [x2, y2] = [
        x1 * (1 + logoOffset / radius),
        y1 * (1 + logoOffset / radius),
      ];

      // Update or add lines
      g.selectAll(`line#line-${i}`)
        .data([interpolatedData])
        .join(
          (enter) =>
            enter
              .append('line')
              .attr('id', `line-${i}`)
              .attr('class', 'donut-line')
              .attr('stroke', 'black')
              .style('fill', 'none')
              .attr('stroke-width', 1)
              .attr('x1', x1)
              .attr('y1', y1)
              .attr('x2', x2)
              .attr('y2', y2),
          (update) =>
            update.attr('x1', x1).attr('y1', y1).attr('x2', x2).attr('y2', y2),
          (exit) => exit.remove(),
        );

      // Update or add text
      g.selectAll(`text#text-${i}`)
        .data([interpolatedData])
        .join(
          (enter) =>
            enter
              .append('text')
              .attr('id', `text-${i}`)
              .attr('class', 'donut-text')
              .attr('x', x1 * (1 + (logoOffset / radius) * 0.5))
              .attr('y', y1 * (1 + (logoOffset / radius) * 0.5))
              .attr('dy', 10)
              .attr('text-anchor', 'middle')
              .attr('transform', function () {
                const midangle =
                  (interpolatedData.startAngle + interpolatedData.endAngle) / 2;
                const angle = midangle * (180 / Math.PI);
                const rotate = angle > 180 ? angle - 90 + 180 : angle - 90;
                return `rotate(${rotate}, ${x1 * (1 + (logoOffset / radius) * 0.5)}, ${y1 * (1 + (logoOffset / radius) * 0.5)})`;
              })
              .text(`${interpolatedData.data.percentageOfTotal}%`)
              .style('font-size', '13px')
              .style('alignment-baseline', 'middle'),
          (update) =>
            update
              .attr('x', x1 * (1 + (logoOffset / radius) * 0.5))
              .attr('y', y1 * (1 + (logoOffset / radius) * 0.5))
              .attr('transform', function () {
                const midangle =
                  (interpolatedData.startAngle + interpolatedData.endAngle) / 2;
                const angle = midangle * (180 / Math.PI);
                const rotate = angle > 180 ? angle - 90 + 180 : angle - 90;
                return `rotate(${rotate}, ${x1 * (1 + (logoOffset / radius) * 0.5)}, ${y1 * (1 + (logoOffset / radius) * 0.5)})`;
              }),
          (exit) => exit.remove(),
        );

      // Update or add logos
      g.selectAll(`g#logo-${i}`)
        .data([interpolatedData])
        .join(
          (enter) =>
            enter
              .append('g')
              .attr('id', `logo-${i}`)
              .attr('class', 'logo')
              .attr('transform', `translate(${x2},${y2})`)
              .each(function () {
                const group = select(this);

                group
                  .append('rect')
                  .attr('width', `${baseLogoSize}px`)
                  .attr('height', `${baseLogoSize}px`)
                  .attr('x', -baseLogoSize / 2)
                  .attr('y', -baseLogoSize / 2)
                  .attr('fill', 'white');

                group
                  .append('image')
                  .attr('xlink:href', interpolatedData.data.logoUrl)
                  .attr('width', `${baseLogoSize}px`)
                  .attr('height', `${baseLogoSize}px`)
                  .attr('x', -baseLogoSize / 2)
                  .attr('y', -baseLogoSize / 2);
              }),
          (update) => update.attr('transform', `translate(${x2},${y2})`),
          (exit) => exit.remove(),
        );
    }
  };

  // Initial animation on mount
  useEffect(() => {
    renderChart(data, true);
    setHasAnimated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle data updates after initial animation
  useEffect(() => {
    if (hasAnimated) {
      renderChart(data, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <svg ref={chartRef} style={{ width: '100%', height: 'auto' }}></svg>;
};
